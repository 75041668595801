import React, { useState, useEffect } from 'react';
import Navbar from './Navbar'; // Ajusta la ruta según sea necesario
import '../stylesheets/PoolsIndex.css';

const PoolsIndex = ({ userSignedIn, currentUser, csrfToken, pools }) => {
  const [filteredPools, setFilteredPools] = useState([]);
  const [myPools, setMyPools] = useState([]);

  useEffect(() => {
    console.log("Current User:", currentUser);
    console.log("Pools:", pools);
    
    if (pools && currentUser) {
      const publicPools = pools.filter(pool => pool.isPublic && !pool.isFinished);
      setFilteredPools(publicPools);
  
      const myPools = pools.filter(pool => 
        Array.isArray(pool.participants) && pool.participants.includes(currentUser.id)
      );
      console.log("My Pools after filtering:", myPools);
      setMyPools(myPools);
    }
  }, [pools, currentUser]);  

  if (!pools.length) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar userSignedIn={userSignedIn} csrfToken={csrfToken} />
      
      <div className="container">
        <h1 className="title">Quinielas Públicas</h1>
        
        {filteredPools.length > 0 ? (
          <div id="pools" className="pools-list">
            {filteredPools.map(pool => (
                <div key={pool.id} className="pool-item">
                  <p><strong>Nombre:</strong> {pool.title}</p>
                  <p><strong>Descripción:</strong> {pool.description}</p>
                  <p><strong>Premio:</strong> {pool.prize}</p>
                  <button className="main-button" onClick={() => window.location.href = `/pools/${pool.id}`}>Ver Quiniela</button>
                </div>
            ))}
          </div>
        ) : (
          <div className="pools-list" style={{color: 'white', textAlign: 'center'}}>
            <p>No hay quinielas públicas disponibles en este momento.</p>
          </div>
        )}

        {myPools.length > 0 && (
          <>
            <h1 className="title">Quinielas en las que ya participas</h1>
            
            <div id="pools" className="pools-list">
              {myPools.map(pool => (
                <div key={pool.id} className="pool-item">
                  <p><strong>Nombre:</strong> {pool.title}</p>
                  <p><strong>Descripción:</strong> {pool.description}</p>
                  <p><strong>Premio:</strong> {pool.prize}</p>
                  <button className="main-button" onClick={() => window.location.href = `/pools/${pool.id}`}>Ver Quiniela</button>
                </div>
              ))}
            </div>
          </>
        )}

        {userSignedIn && currentUser && currentUser.admin && (
          <div className="creator-actions">
            <a href="/pools/new" className="new-pool-link">New pool</a>
          </div>
        )}
      </div>
    </>
  );
};

export default PoolsIndex;
