import React, { useEffect, useState } from 'react';
import Navigation from './Navigation';
import '../stylesheets/Leaderboard.css';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';

const Leaderboard = ({ pool, userSignedIn, csrfToken }) => {
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (pool && pool.id) {
      fetch(`/pools/${pool.id}/leaderboard.json`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (data && data.participants) {
            setParticipants(data.participants);
          }
          setLoading(false);  // Fin de la carga
        })
        .catch(error => {
          setError(error);
          setLoading(false);  // Fin de la carga en caso de error
        });
    }
  }, [pool]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!participants || participants.length === 0) return <div>No participants data</div>;

  const sortedParticipants = participants.sort((a, b) => b.total_points - a.total_points);

  const getRankEmoji = (rank) => {
    switch (rank) {
      case 1:
        return '🥇';
      case 2:
        return '🥈';
      case 3:
        return '🥉';
      default:
        return '';
    }
  };

  return (
    <>
      <Navbar userSignedIn={userSignedIn} csrfToken={csrfToken} />
      <div className="tabs">
        <Link to={`/pools/${pool.id}`} className={`tab ${location.pathname === `/pools/${pool.id}` ? 'active' : ''}`}>Partidos</Link>
        <Link to={`/pools/${pool.id}/leaderboard`} className={`tab ${location.pathname === `/pools/${pool.id}/leaderboard` ? 'active' : ''}`}>Leaderboard</Link>
      </div>
      <div className="container">
        <h1 className="title">Leaderboard</h1>
        <table className="leaderboard-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Puntos</th>
              <th>Goles</th>
            </tr>
          </thead>
          <tbody>
            {sortedParticipants.map((participant, index) => (
              <tr key={participant.id} className={`rank-${index + 1}`}>
                <td>{ index + 1 } {getRankEmoji(index + 1)}</td>
                <td className='profile-picture-td'>
                  {participant.profile_picture_url !== null ? (
                    <img src={ participant.profile_picture_url } alt={ participant.first_name } className="profile-picture" />
                  ) : (
                    <div className="profile-placeholder">{participant.first_name.charAt(0).toUpperCase()}</div>
                  )}
                  { participant.username.length > 18 ? participant.username.substring(0, 13) + '...' : participant.username }
                </td>
                <td>{ participant.total_points }</td>
                <td>{ participant.tie_breaker_goals }</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Leaderboard;
