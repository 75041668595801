import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../stylesheets/UserProfile.css';
import Navbar from './Navbar';

const UserProfile = ({ user, userSignedIn, csrfToken }) => {
  useEffect(() => {
    console.log('user: ', user);
  }, [user])
  
  const navigate = useNavigate();

  const handleEditProfile = () => {
    navigate('/profile/edit');
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar userSignedIn={userSignedIn} csrfToken={csrfToken} />
      <div className="user-profile">
        <div className="profile-profile-picture-container">
          {user.profile_picture_url ? (
            <img src={user.profile_picture_url} alt="Profile Picture" className="profile-profile-picture" />
          ) : (
            <div className="profile-placeholder">
              {user.first_name ? user.first_name.charAt(0).toUpperCase() : ''}</div>
          )}
        </div>
        <h1>{user.first_name} {user.last_name}</h1>
        <p>Email: {user.email}</p>
        <p>Username: {user.username}</p>
        <button className="edit-profile-button" onClick={handleEditProfile}>Editar Perfil</button>
        {/* Referral code */}
        {
          userSignedIn && user.referral_code && (
            <div>
              <p>Código de Referido: {user.referral_code}</p>
            </div>
          )
        }
      </div>
    </>
  );
};

export default UserProfile;
